@use "sass:map";
@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";
@import "../../styles/mixins/hub/hub-button-focus";

.header {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.privacyEditForm a {
  position: relative;
  display: inline;
  align-items: center;

  &:focus {
    @include no-padding-button-on-focus;
  }
}

.privacyEditForm a::after {
  position: absolute;
  width: 20px;
  height: 100%;
  margin-right: -20px;
  margin-left: 8px;
  content: url("data:image/svg+xml;utf8,<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' fill='%234776ae' viewBox='0 0 24 24'><title>icon-external</title><path d='M20,5.17a.74.74,0,0,0-.21-.57.78.78,0,0,0-.57-.23H19l-3.53,0a.81.81,0,0,0-.79.8v.31a.87.87,0,0,0,.85.75h1.31l-6.51,6.49A.93.93,0,0,0,11.63,14l6.52-6.5V8.88a.82.82,0,0,0,.24.59.72.72,0,0,0,.55.22h.26A.76.76,0,0,0,20,8.94V5.17Z'/><path d='M17.36,10.53a.93.93,0,0,0-.93.92h0v6.34H5.85V7.93h7.06a.93.93,0,0,0,0-1.86H5.42A1.43,1.43,0,0,0,4,7.5V18.22a1.43,1.43,0,0,0,1.42,1.42H16.86a1.43,1.43,0,0,0,1.42-1.42V11.45h0A.92.92,0,0,0,17.36,10.53Z'/></svg>");
}

.initials {
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 0.625rem;
  background-color: map.get($secondary-colors, register);
  color: map.get($legacy-color-names, tooltip);
  font-size: 16px;
  font-weight: 400;
}

.heading {
  margin: 0;
}

.userName {
  margin-top: -5px;
  color: map.get($hubColors, dark-gray);
  font-size: 16px;
}

.personalInfoContainer {
  margin-top: 34px;
}

.usernameHeader {
  margin: 15px 0 5px;
  font-weight: 600;
}

.usernameHelpText {
  font-style: italic;
}

.passwordGuidanceText,
.passwordGuidanceNote {
  margin-top: 10px;
  color: map.get($core, icons);
}

.passwordGuidanceNote {
  span {
    margin-right: 5px;
    font-weight: 600;
  }
}

.usernameTitle {
  margin-top: 0;
}

.forgottenPasswordLink {
  margin-top: 10px;
  text-decoration: none;
}

.socialMainContainer {
  padding-left: 0;
}

.linkHeader {
  margin: 10px 0;
}

.personalInformationHeader {
  font-weight: 900;
}

.changeText {
  @media screen {
    line-height: 1.2em !important;

    span {
      display: inline;
      white-space: unset;
    }
  }

  @media screen and (width <= 320px) {
    font-size: 10px;
  }

  @media screen and (321px <= width <= 380px) {
    font-size: 12px;
  }

  @media screen and (381px <= width <= 420px) {
    font-size: 14px;
  }
}

.button {
  &.backButton {
    width: auto;
    min-width: 75px;
    background-color: transparent;
    float: left;

    &:hover {
      background: transparent;
      color: map.get($hubColors, blue-color-dark);

      svg {
        fill: map.get($hubColors, blue-color-dark);
      }
    }
  }

  &.saveButton {
    position: relative;
    top: -0.75rem;
    width: auto;
    min-width: 75px;
    max-width: 75px;
    border-bottom-color: transparent;
    float: right;
  }

  &.socialBackButton {
    width: auto;
    background-color: transparent;

    &:hover {
      background: transparent;
      color: map.get($hubColors, blue-color-dark);

      svg {
        fill: map.get($hubColors, blue-color-dark);
      }
    }
  }
}

@mixin register-button {
  @media screen and (width >= 280px) {
    width: 270px;
  }

  height: 53px;
  border: 1px solid $color-astronaut-blue;
  border-radius: 4px;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  font-size: 15px;
}

@mixin social-registration-buttons {
  @include register-button;

  position: relative;
  display: flex;

  @media screen and (width >= 451px) {
    width: 310px;
  }

  height: 40px;
  align-items: center;
  padding: 0 20px;
  padding-left: 7px;
  border-width: 2px;
  border-style: solid;
  border-color: $contrast-dark;
  border-radius: 20px;
}

.socialRegistrationButtonWithEmail {
  @include social-registration-buttons;

  img {
    height: 100%;
    padding: 6px;
    margin: 0 12px;
    margin-left: 0;
  }

  span {
    overflow: hidden;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.registerButtonEmail {
  @include register-button;
}

.registerButtonApple,
.registerButtonGoogle,
.registerButtonMicrosoft {
  @include social-registration-buttons;

  img {
    height: 100%;
    padding: 6px;
    margin: 0 12px;
    margin-left: 0;
  }

  span {
    overflow: hidden;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.registerButtonApple:focus,
.registerButtonApple:hover,
.registerButtonGoogle:focus,
.registerButtonGoogle:hover,
.registerButtonMicrosoft:focus,
.registerButtonMicrosoft:hover {
  box-shadow: 0 0 5px 5px $contrast-light-box-shadow;
}

.unlinkMainContainer {
  display: flex;

  @media screen and (width >= 451px) {
    flex-direction: column;
  }
}

.linkMainContainer {
  display: flex;
  flex-direction: column;
}

.unlinkItem {
  display: flex;
  align-items: center;

  @media screen and (280px <= width <= 450px) {
    flex-direction: column;
    font-size: 14px;
  }
}

.unlinkItem .registerButtonGoogle:hover,
.unlinkItem .registerButtonMicrosoft:hover {
  box-shadow: none;
}

.unlinkLink {
  color: map.get($background-and-border-colors, background-light-blue);
}

.socialConfirmSubtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  color: $color-grey-border;
  font-size: 16px;
  text-align: center;
}

.socialConfirmPanelContainer {
  margin-top: -30px;
}

.usernameLabel, .oldPasswordLabel {
  display: block;
  margin: 15px 0 5px;
  font-weight: 600;
}

.usernameOxfordId {
  font-weight: 400;
}

.addToClassLabel {
  display: block;
  margin: 15px 0 5px;
  font-weight: 600;
}

.supervisedOldPassword {
  margin-top: 2rem;
}

.supervisedUsername svg,
.supervisedOldPassword svg {
  align-self: flex-end;
}

.bannerContainer {
margin-top: 0.5rem;

  .infoContent a {
    margin: 0 5px;
  }

  svg {
    align-self: flex-start;
  }
}

.actionButton {
  button {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    margin-top: 0.75rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
